import React from "react";
import YouTube from 'react-youtube';
import ReactPannellum, { getConfig, addScene, getAllScenes} from "react-pannellum";
import {Box, Grid, Dialog, DialogContent, DialogTitle, Backdrop, Typography, Button, IconButton, SpeedDial} from "@mui/material";
import './cssMain.css';
import { VolumeOff, VolumeUp } from "@mui/icons-material";

export default class Main extends React.Component {

    constructor(props) 
    {
        super();

        this.state = {
            windowSize: {width: window.innerWidth, height: window.innerHeight},
            dialogImage: {
                open: false,
                image: "images/detail1.jpg"
            },
            dialogVideo: {
                open: false,
                url: ""
            },
            backDrop: true,
            audio: new Audio("audio/bgm.mp3"),
            isLandscape: false
        }

        this.firstClick = true;
    }

    componentDidMount()
    {
        let self = this;
        window.addEventListener('resize', this.OnWindowResize);
        window.focus();
        this.state.audio.loop = true;
        this.state.audio.volume = 0.2;

        self.setState({isLandscape: window.innerWidth > window.innerHeight})
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.OnWindowResize);
    }


    // Event 

    //OnVisibilityChange = (forcedFlag) => {
    //    let focus = true;
    //    if (typeof forcedFlag === 'boolean') {
    //        focus = forcedFlag;
    //    }
    //    this.setState({playSound: focus});
    //}

    OnWindowResize = () => {
        let windowSize = this.state.windowSize;
        windowSize.width = window.innerWidth;
        windowSize.height = window.innerHeight;

        let isLand = window.innerWidth > window.innerHeight;
        let previousLand = this.state.isLandscape;

        this.setState({windowSize: windowSize, isLandscape: window.innerWidth > window.innerHeight}, () => 
        {
            if (!previousLand && isLand)
            {
                window.location.reload(false);
            }
        });
    }

    OnRenderPreviewImage = () => {
        return (
            <Dialog open={this.state.dialogImage.open} fullWidth={true} maxWidth={"lg"} onClick={() => 
            {
                let image = this.state.dialogImage;
                image.open = false;
                this.setState({dialogImage: image});
            }}>
                <DialogContent>
                    <img src={this.state.dialogImage.image} width="100%" height="auto"/>
                </DialogContent>
            </Dialog>
        )
    }

    OnRenderPreviewVideo = () => {

        let width = 560;
        let ratio = 315.0 / 560.0;

        if (window.innerWidth < 560)
        {
            width = window.innerWidth - 40;
        }

        const opts = {
            height: width * ratio,
            width: width,
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
            },
        }


        return (
            <Dialog open={this.state.dialogVideo.open} onClick={() => 
            {
                let image = this.state.dialogVideo;
                image.open = false;
                this.setState({dialogVideo: image, playSound: true});
                this.state.audio.play();
            }}>
                {/*<YouTube videoId="0gpVS3iNdUY" opts={opts}/>*/}
                <YouTube videoId={this.state.dialogVideo.url} opts={opts}/>
            </Dialog>
        )
    }

    RenderButtonAudio = () => {
        return (
            <SpeedDial
                ariaLabel="Audio"
                onClick={() => 
                {
                    this.state.audio.muted = !this.state.audio.muted;
                    this.forceUpdate();
                }}
                sx={{ position: 'absolute', top: 16, right: 16}}
                FabProps={{ style: {backgroundColor: "white"}}}
                icon = {this.state.audio.muted ? <VolumeOff color="secondary"/>  : <VolumeUp color="success"/>}
            >
            </SpeedDial>
        )
        //return (
        //    <Button color="inherit" style={{position: "fixed", top: "1em", right: "1em", backgroundColor: "white", transform: "translate3d(0,0,0)"}}
        //        onClick={() => 
        //        {
        //            this.state.audio.muted = !this.state.audio.muted;
        //            this.forceUpdate();
        //        }}
        //    >
        //        {this.state.audio.muted ? <VolumeOff color="secondary"/>  : <VolumeUp color="success"/>}
        //    </Button>
        //)
    }


    RenderBlockPortrait = () => {

        return (
            (!this.state.isLandscape && 
                <Box sx={{position:"fixed", top:0, left:0, height: "100%", width:"100%", backgroundColor: "black"}}>
                    <Grid spacing={1} container direction="row" justifyContent="center" alignItems="center" width="100%" height="100%" >
                        <Grid item>
                            <img src="images/rotate.png" width="auto" height="auto"></img>
                        </Grid>
                    </Grid>
                </Box>
            )
        )
    }

    render() {
        const config = {
            autoLoad: true,
            yaw: 0,
            pitch: 0,
            hfov: 220,
            mouseZoom: false,
            disableKeyboardCtrl: true,
            avoidShowingBackground: true,
            hotSpots: [
                {
                    yaw: -73,
                    pitch: -10,
                    type: "scene",
                    targetYaw: 0,
                    sceneId: "00002",
                    cssClass: "custom-hotspot-arrow",
                    scale: true,
                },
                {
                    yaw: -44,
                    pitch: -13,
                    type: "scene",
                    targetYaw: 0,
                    sceneId: "00003",
                    cssClass: "custom-hotspot-arrow",
                    scale: true,
                },
            ]
        };


        return (
            <Grid container >
                <Grid item onClick={()=> {
                    if(!this.firstClick) return;
                    this.firstClick = false;
                    this.state.audio.play();
                }}>

                {(this.state.isLandscape && 
                <ReactPannellum
                    id="1"
                    sceneId="00001"
                    imageSource="images/update4/img00001.jpg"
                    config={config}
                    style={{ width: window.innerWidth, height: window.innerHeight }}
                    onPanoramaLoaded={()=>{
                        ReactPannellum.addScene("00002", {
                            autoLoad: true,
                            imageSource: "images/update4/img00002.jpg",
                            hfov: 220,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
								{
                                    yaw: 90,
                                    pitch: -10,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00001",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: 73,
                                    pitch: -17,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00003",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
								},
								{
									yaw: -2,
                                    pitch: -1,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "OhqDLY4-kow";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
								},

                                /*,
                                {
                                    pitch: -17,
                                    yaw: 4,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "c9oBAXRKovI";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },*/
                            ]
                        });

                        ReactPannellum.addScene("00003", {
                            autoLoad: true,
                            imageSource: "images/update4/img00003.jpg",
                            hfov: 220,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
                                {
                                    yaw: 41,
                                    pitch: -16,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00007",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -42,
                                    pitch: -14,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00004",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -133,
                                    pitch: -20,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00002",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                            ]
                        });
                        ReactPannellum.addScene("00004", {
                            autoLoad: true,
                            imageSource: "images/update4/img00004.jpg",
                            hfov: 100,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
                                {
                                    yaw: -63,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot-lg",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogImage;
                                        window.open = true;
                                        window.image = "images/update4/image_4.jpg"
                                        this.setState({ dialogImage: window })
                                    }
                                },
                                {
                                    yaw: -71,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "eBbeowIMbAU";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },
                                {
                                    yaw: -4,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot-lg",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogImage;
                                        window.open = true;
                                        window.image = "images/update4/image_3.jpg"
                                        this.setState({ dialogImage: window })
                                    }
                                },
                                {
                                    yaw:  4,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "eBbeowIMbAU";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },
                                {
                                    yaw: 71,
                                    pitch: -20,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00005",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
								{
                                    yaw: -135,
                                    pitch: -19,
                                    type: "scene",
                                    targetYaw: 200,
                                    sceneId: "00003",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
								},
                            ]
                        });
                        ReactPannellum.addScene("00005", {
                            autoLoad: true,
                            imageSource: "images/update4/img00005.jpg",
                            hfov: 220,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
                                {
                                    yaw: -1,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot-lg",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogImage;
                                        window.open = true;
                                        window.image = "images/update4/image_2.jpg"
                                        this.setState({ dialogImage: window })
                                    }
                                },
                                {
                                    yaw:   7,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "eBbeowIMbAU";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },
                                {
                                    yaw: 48,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot-lg",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogImage;
                                        window.open = true;
                                        window.image = "images/update4/image_1.jpg"
                                        this.setState({ dialogImage: window })
                                    }
                                },
                                {
                                    yaw:   57,
                                    pitch: 5,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "eBbeowIMbAU";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },
                                {
                                    yaw: 64,
                                    pitch: -19,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00006",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -74,
                                    pitch: -19,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00004",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: 118,
                                    pitch: -18,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00007",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },

                            ]
                        });
                        ReactPannellum.addScene("00006", {
                            autoLoad: true,
                            imageSource: "images/update4/img00006.jpg",
                            hfov: 220,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
                                {
                                    yaw: -1,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot-lg",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogImage;
                                        window.open = true;
                                        window.image = "images/update4/image_1.jpg"
                                        this.setState({ dialogImage: window })
                                    }
                                },
                                {
                                    yaw:   9,
                                    pitch: 6,
                                    type: "info",
                                    cssClass: "custom-hotspot",
                                    scale: true,
                                    clickHandlerFunc: () => {
                                        let window = this.state.dialogVideo;
                                        window.open = true;
                                        window.url = "eBbeowIMbAU";
                                        this.setState({ dialogVideo: window, playSound: false })
                                        this.state.audio.pause();
                                    }
                                },
                                {
                                    yaw: -83,
                                    pitch: -12,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00004",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -59,
                                    pitch: -25,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00005",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -149,
                                    pitch: -23,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00007",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },

                            ]
                        });
                        ReactPannellum.addScene("00007", {
                            autoLoad: true,
                            imageSource: "images/update4/img00007.jpg",
                            hfov: 220,
                            mouseZoom: false,
                            disableKeyboardCtrl: true,
                            avoidShowingBackground: true,
                            hotSpots: [
                                {
                                    yaw: -178,
                                    pitch: -28,
                                    type: "scene",
                                    targetYaw: 200,
                                    sceneId: "00003",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -91,
                                    pitch: -13,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00005",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                                {
                                    yaw: -63,
                                    pitch: -12,
                                    type: "scene",
                                    targetYaw: 0,
                                    sceneId: "00006",
                                    cssClass: "custom-hotspot-arrow",
                                    scale: true,
                                },
                            ]
                        });
                    }}
                />) }
                {(this.state.isLandscape && this.OnRenderPreviewImage())}
                {(this.state.isLandscape && this.OnRenderPreviewVideo())}
                {(this.state.isLandscape && this.RenderButtonAudio())}

                {this.RenderBlockPortrait()}

                </Grid>
            </Grid>
        );
    }
}